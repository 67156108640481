import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
    :root {
        --ivory: #fcfbf9;
        --graphite: #444544;
        --light-green: #8ad2c9;
        --green: #329187;
        --orange: #e99150;
    }

    html {
        color: var(--graphite);
        background-color: var(--ivory);
    }

    body {
        max-width: 960px;
        min-height: 100vh;
        margin: 32px auto 32px auto;
        padding: 16px;
    }
`;

export default GlobalStyles;