import { createGlobalStyle } from 'styled-components';
import latoThin from '../assets/fonts/Lato-Thin.ttf';
import latoRegular from '../assets/fonts/Lato-Regular.ttf';
import latoBold from '../assets/fonts/Lato-Bold.ttf';
import latoBlack from '../assets/fonts/Lato-Black.ttf';
import CharterRegular from '../assets/fonts/Charter-Regular.ttf';
import CharterBold from '../assets/fonts/Charter-Bold.ttf';
import titleFont from '../assets/fonts/IMFellEnglish-Regular.ttf';

const Typography = createGlobalStyle`
  @font-face {
    font-family: Lato-Regular;
    src: url(${latoRegular});
  }
  @font-face {
    font-family: Lato-Thin;
    src: url(${latoThin});
  }
  @font-face {
    font-family: Lato-Bold;
    src: url(${latoBold});
  }
  @font-face {
    font-family: Lato-Black;
    src: url(${latoBlack});
  }
  @font-face {
    font-family: IMFellEnglish;
    src: url(${titleFont});
  }
  @font-face {
    font-family: Charter-Regular;
    src: url(${CharterRegular});
  }
  @font-face {
    font-family: Charter-Bold;
    src: url(${CharterBold});
  }
  html {
    font-family: Charter-Regular, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  h1,h2,h3,h4,h5,h6 {
    font-family: Charter-Bold;
    color: var(--green);
    text-align: center;
  }
  h1 {
      font-size: 40px;
  }
  h2 {
      font-size: 32px;
  }
  h3 {
      font-size: 28px;
  }
`;

export default Typography;