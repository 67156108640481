import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import chip from '../assets/images/chip.png';

const NavStyles = styled.ul`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-transform: uppercase;
    font-size: 12px;
    list-style-type:none;
    img {
        height: 45px;
    }
    li {
        padding: 16px;
        display: inline-block;
    }
    a {
    color: inherit;;
    text-decoration: none;
    }
    a:hover {
        color: inherit;; 
        text-decoration:none; 
        cursor:pointer;  
    }

`
function Nav() {
    return (
        <NavStyles>
            <li>
                <Link to='/'>
                    <img src={chip} alt=""/>
                </Link>
            </li>
            <li>
                <Link to='/blog'>Blog</Link>
            </li>
            <li>
                <Link to='/portfolio'>About</Link>
            </li>
            <li>
                <Link to='/projects'>Projects</Link>
            </li>
        </NavStyles>
    )
}


export default Nav;