import React from 'react';
import { Link } from 'gatsby';
import gmail from '../assets/images/icons/icon-gmail.png';
import github from '../assets/images/icons/icon-github.png';
import linkedin from '../assets/images/icons/icon-linkedin.png';
import styled from 'styled-components';

const FooterStyles = styled.div`
    /* background-color: var(--graphite); */
    /* color: var(--ivory); */
    margin-top: 64px;
    /* max-width: 1400px; */
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    text-align: center;
    /* text-transform: uppercase; */
    font-size: 16px;
    .contact {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            height: 25px;
        }
    }
    ul {
        display: flex;
        align-items: center;
        list-style-type:none;
        padding-left: 0; // remove default of 40px
        li {
            padding: 16px;
        }
    }
    /* a {
    color: inherit;;
    text-decoration: none;
    }

    a:hover {
        color: inherit;; 
        text-decoration:none; 
        cursor:pointer;  
    } */
`
function Footer() {
    return (
        <FooterStyles>
    <footer>
        <hr/>
        <div className='contact'>
            <p>Feel free to reach out to discuss web development:</p>
            <ul>
                <li>
                    <a target="_blank" href="mailto:cerizesantos@gmail.com">
                        <img src={gmail} alt="" />
                    </a>
                </li>
                <li>
                    <a target="_blank" href="https://github.com/cerize">
                        <img src={github} alt="" />
                    </a>
                </li>
                <li>
                    <a target="_blank" href="https://ca.linkedin.com/in/cerize" >
                        <img src={linkedin} alt="" />
                    </a>
                </li>
            </ul>
        </div>
        <p>
            &copy; Cerize Santos 2020
        </p>
        <p>
            All retro illustrations from The British Library <a href="https://www.flickr.com/photos/britishlibrary/">(Check it out, they are pretty cool!)</a>
        </p>
        <p>
            Most icons from <a href="https://www.flaticon.com/" title="Flat Icons">Flat Icons</a>.
        </p> 
        
    </footer>
        </FooterStyles>
    )
}


export default Footer;